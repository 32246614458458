import request from '@/util/request'

export function GetShopComplainList(params) {
  return request({
    url: '/ShopComplain.asmx/GetShopComplainList',
    method: 'GET',
    params,
  })
}

export function GetShopComplainInfo(params) {
  return request({
    url: '/ShopComplain.asmx/GetShopComplainInfo',
    method: 'GET',
    params,
  })
}

export function ShopComlainCellBack(params) {
  return request({
    url: '/ShopComplain.asmx/ShopComlainCellBack',
    method: 'GET',
    params,
  })
}
